<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="addItem">新增</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="labelVisible"
      :title="title"
      width="50%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="writeoff_box">
        <span><i>*</i>字典名称：</span>
        <el-input
          v-model="from.name"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>字典类型：</span>
        <el-select
          v-model="from.type"
          class="dialog_input"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>字典值：</span>
        <el-input
          v-model="from.data"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>排序：</span>
        <el-input
          v-model="from.sort"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span>备注：</span>
        <el-input
          v-model="from.remark"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
          :rows="4"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage } from 'element-plus'
import {
  dictAdd,
  dictEdit,
  dictList,
  dictQueryList
} from '@/api/getUser/dictionaryManaged'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '字典名称',
      placeholder: '请输入字典名称'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    way: '',
    status: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'id', label: 'ID' },
    { prop: 'value', label: '字典名称' },
    { prop: 'dictName', label: '字典类型' },
    { prop: 'code', label: '字典值' },
    { prop: 'remark', label: '备注' },
    { prop: 'sort', label: '排序' },
    { slot: 'action' }
  ],
  from: {
    name: '',
    type: '',
    data: '',
    sort: '',
    remark: ''
  },
  typeList: [],
  labelVisible: false,
  title: '',
  id: ''
})

onMounted(() => {
  getDictQueryList()
  getDataList()
})

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    way: '',
    status: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取主题活动列表
const getDictQueryList = () => {
  dictQueryList({ type: 1 }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.groupName,
          value: item.id
        }
      })
      typeList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 修改状态
const editItem = row => {
  title.value = '修改'
  from.value = {
    name: row.value,
    type: row.dictId,
    data: row.code,
    sort: row.sort,
    remake: ''
  }
  id.value = row.id
  labelVisible.value = true
}

// 新增
const addItem = () => {
  from.value = {
    name: '',
    type: '',
    data: '',
    sort: '',
    remake: ''
  }
  title.value = '新增'
  labelVisible.value = true
}

const labelClose = () => {
  from.value = {
    name: '',
    type: '',
    data: '',
    sort: '',
    remake: ''
  }
  labelVisible.value = false
}

// 提交
const remarkSubmit = () => {
  if (from.value.name == '') {
    ElMessage.warning('请输入字典名称')
    return false
  }
  if (from.value.type == '') {
    ElMessage.warning('请输入字典类型')
    return false
  }
  if (from.value.data == '') {
    ElMessage.warning('请输入字典值')
    return false
  }
  if (from.value.sort == '') {
    ElMessage.warning('请输入排序')
    return false
  }
  const data = {
    value: from.value.name,
    code: from.value.data,
    dictId: from.value.type,
    sort: Number(from.value.sort)
  }
  if (from.value.remark != '') {
    data.remark = from.value.remark
  }
  if (id.value == '') {
    dictAdd(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = id.value
    dictEdit(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.keyword = fromData.value.keywords
  }
  console.log(data);
  dictList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  from,
  labelVisible,
  title,
  id,
  typeList
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  span {
    width: 100px;
    text-align: right;
  }
  .dialog_input {
    width: 50%;
  }
  i {
    color: red;
  }
}

.remark_box {
  align-items: flex-start;
}
</style>
